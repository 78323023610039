import { inset } from '../../utils'

export default {
  __default: {
    p: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    height: 'var(--logo-y)',
    width: 'fit-content',

    svg: {
      width: 'unset',
    },
  },
  navs: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',

    nav: {
      alignItems: 'flex-start',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {
      whiteSpace: 'nowrap',
    },
  },
  items: {
    display: 'flex',
    minWidth: [null, null, 'calc(((100vw - var(--padding-x) * 4) / 6) * 2)'],

    '[data-connected]': {
      flexGrow: 1,
      justifyContent: 'space-between',
    },

    '[data-bullet]': {
      display: 'inline-block',
      size: '1.58em',
      my: '-1.25em',
      mr: ['-0.65em', null, '-0.5em'],
      ml: '0.75em',
      background: 'var(--gradient-short)',
      borderRadius: '50%',
    },
  },
  burger: {
    userSelect: 'none',
    p: 'var(--p-offset)',
    '--radius': '50%',

    '&[data-long=false]': {
      '--size': '2.3rem',
      '--fill-bg': (t) => t.colors?.background,
      '--fill-fg': (t) => t.colors?.text,
    },
    '&[data-long=true]': {
      '--size': '1.5rem',
      '--fill-bg': 'var(--bg)',
      '--fill-fg': 'var(--text)',

      '>span': {
        display: 'flex',
        alignItems: 'center',
        gap: '2.5em',

        my: '-0.15em',
      },

      '[data-fill]': {
        my: '-0.25em',
      },
    },

    '[data-fill]': {
      position: 'relative',

      borderWidth: 'var(--stroke-x)',
      borderStyle: 'solid',
      borderColor: 'var(--fill-fg)',

      bg: 'var(--fill-bg)',
      size: 'var(--size)',
      borderRadius: 'var(--radius)',

      '::before': {
        content: '""',
        position: 'absolute',
        borderRadius: '50%',

        bg: 'var(--fill-fg)',
        transform: 'scale(var(--to))',
        ...inset(0),

        transition: 'transform var(--ui-duration) var(--ui-ease)',
        willChange: 'transform',
      },
    },

    '&[data-active=true]': { '--to': '0.55' },
    '&[data-active=false]': { '--to': '1.05' },

    '@media (hover: hover)': {
      '&:hover [data-fill]::before': { transform: 'scale(1.05)' },
    },
  },
  socials: {
    display: 'flex',
    gap: '0.75em',
    svg: {
      height: '75%!important',
    },
  },
}
