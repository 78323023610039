import { MsgExecuteContract, Coins } from '@terra-money/terra.js'
import {
  CreateTxFailed,
  Timeout,
  TxFailed,
  TxUnspecifiedError,
  useConnectedWallet,
  UserDenied,
} from '@terra-money/wallet-provider'

import { useData } from '../../DataProvider'
import { CONTRACT, getClient } from '../../__consts'

const useSubmission = () => {
  const { modal, cropAddress, setUserDataRefreshCount } = useData() ?? {}

  const connectedWallet = useConnectedWallet()

  const hashObject = (hash = '', name = 'Transaction Hash') => ({
    name,
    value: cropAddress(hash, 6),
    url: CONTRACT.TRANSACTION_URL + hash,
  })

  const send = async ({
    senderWallet = connectedWallet.walletAddress,
    contractAddress = CONTRACT.ADDRESS,
    msgProps = [],
    denom = 'uluna',
    gasPrice,
    onSuccess = () => {},
  }) => {
    if (!connectedWallet) return null

    // prettier-ignore
    const msgs = [new MsgExecuteContract(
      senderWallet,
      contractAddress,
      ...msgProps
    )]

    const terra = getClient()

    let fees = {
      gas: '2000000',
      gasAdjustment: 1.5,
    }

    if (denom === 'uluna') {
      delete fees['gas']
    }

    const account = await terra.auth.accountInfo(senderWallet)
    const sequenceNumber = account.getSequenceNumber()

    modal.push({
      type: 'pending',
      title: 'Validating Transaction',
      message: ['This transaction is in progress'],
    })

    const fee = await terra.tx
      .estimateFee([{ sequenceNumber }], {
        msgs,
        gasPrices: new Coins(`${gasPrice}${denom}`),
        feeDenoms: [denom],
        ...fees,
      })
      .catch((error) => {
        const message = error.response.data.message
          .replace('failed to execute message; message index: 0: ', '')
          .replace(': execute wasm contract failed: invalid request', '')

        modal.push({
          type: 'error',
          title: 'Create Tx Failed',
          message: [message],
        })

        throw error
      })

    modal.push({
      type: 'pending',
      title: 'Confirm transaction',
      message: ['Proceed with the transaction in your Terra Station'],
    })

    connectedWallet
      .post({ msgs, fee })
      .then((nextTxResult) => {
        modal.push({
          type: 'pending',
          title: 'Validating Transaction',
          message: ['This transaction is in progress'],
          refs: [hashObject(nextTxResult.result.txhash)],
        })
        if (nextTxResult.success) {
          verifyTransaction(nextTxResult, onSuccess)
        }
      })
      .catch((error) => {
        if (error instanceof UserDenied) {
          modal.push({ type: 'error', title: 'User Denied Transaction' })
        } else if (error instanceof CreateTxFailed) {
          modal.push({ type: 'error', title: 'Create Tx Failed', message: [error.message] })
        } else if (error instanceof TxFailed) {
          modal.push({ type: 'error', title: 'Tx Failed', message: [error.message] })
        } else if (error instanceof Timeout) {
          modal.push({ type: 'error', title: 'Timeout' })
        } else if (error instanceof TxUnspecifiedError) {
          modal.push({ type: 'error', title: 'Unspecified Error', message: [error.message] })
        } else {
          modal.push({
            type: 'error',
            title: 'Unknown Error',
            message: [error instanceof Error ? error.message : String(error)],
          })
        }
      })
  }

  let txInfo = null

  const verifyTransaction = async (txResult, onSuccess) => {
    const terra = getClient()

    let timeout = setTimeout(async function run() {
      try {
        txInfo = await terra.tx.txInfo(txResult.result.txhash)
        if (txInfo.raw_log.includes('transfer_nft')) {
          modal.push({ type: 'success', title: 'Transaction was successful!' })
          setUserDataRefreshCount((prev) => prev + 1)
        } else {
          modal.push({
            type: 'error',
            title: 'Transaction Failed',
            message: [txInfo.raw_log],
            refs: [hashObject(txResult.result.txhash)],
          })
        }
      } catch (error) {
        console.log(error)
      }
      if (txInfo != null) {
        clearTimeout(timeout)
        onSuccess()
      } else {
        setTimeout(run, 1000)
      }
    }, 1000)
  }

  return { send }
}

export default useSubmission
