/** @jsxImportSource theme-ui */
import { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Heading } from '@monobits/components'

import Box from './Box'
import Icons from './Icons'
import Button from './Button'

import { useData } from '../DataProvider'
import { times } from '../content'
import { selectedListStatus } from '../atoms'

const pad = (num) => (num <= 9 ? '0' + num : num)
const line = (_) => `${pad(_[0])}:${pad(_[1])}:${pad(_[2])}:${pad(_[3])}`

const Countdown = ({ distance }) => {
  const values = [
    Math.floor(distance / (1000 * 60 * 60 * 24)),
    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
    Math.floor((distance % (1000 * 60)) / 1000),
  ]

  const invalid = !!values.some((i) => Math.sign(i) === -1)
  const content = invalid ? '00:00:00:00' : line(values)

  return <div data-valid={!invalid}>{content}</div>
}

const useTimeValues = (timerange) => {
  const [now, setNow] = useState(new Date())
  const [status, setStatus] = useRecoilState(selectedListStatus)
  let prev = useRef('')

  const [start, end] = timerange.map((i) => new Date(i * 1000))

  const before = start.getTime() - now.getTime()
  const until = end.getTime() - now.getTime()

  const type = before > 0 ? 0 : until > 0 ? 1 : 2

  useEffect(() => {
    setStatus(type)

    const interval = setInterval(() => {
      setNow(new Date())
      if (prev.current !== type) {
        setStatus(type)
      }
      prev.current = type
    }, 1000)

    return () => clearInterval(interval)
  }, [timerange]) // eslint-disable-line

  return { before, until, status: status || type, now }
}

const Time = ({ sx, ...props }) => {
  const {
    select: { nextAvailable, selectNext },
    currentProps: { start_time, end_time },
    user: { stageStatus },
  } = useData() ?? {}

  const ended = stageStatus === 'ended'

  const { before, until, status } = useTimeValues([start_time, end_time])

  const [variant, title, caption, mention] = times[ended ? 3 : status]

  return (
    <Box variant={variant} sx={{ variant: 'time', ...sx }} data-column {...props}>
      <aside>
        <Heading as="h3" variant="list" sx={{ variant: 'time.title' }}>
          {title}
        </Heading>
        <Heading as="span" variant="text" sx={{ variant: 'time.caption' }}>
          {caption}
        </Heading>
      </aside>

      <div sx={{ variant: 'time.foot', position: 'relative' }}>
        {status !== 2 && !ended ? (
          <>
            <Heading as="h4" variant="item" sx={{ variant: 'time.countdown' }}>
              <span data-mention>{mention}</span>
              <Countdown distance={[before, until][status]} />
            </Heading>
          </>
        ) : ended ? (
          <Button
            as={Link}
            to="/collection"
            variant="outlined"
            size="slim"
            sx={{ variant: 'time.countdown' }}
            onClick={selectNext}
          >
            {mention}
          </Button>
        ) : !!nextAvailable ? (
          <Button variant="outlined" size="slim" sx={{ variant: 'time.countdown' }} onClick={selectNext}>
            {mention}
          </Button>
        ) : (
          <span />
        )}
        <div>
          <Icons name="signal" />
        </div>
      </div>
    </Box>
  )
}

export default Time
