import { inset } from '../../utils'

export default {
  __default: {
    visibility: 'hidden',
    clipPath: 'inset(var(--clip-path, 100%) 0 0 0)',
    '--clip-path': '100%',

    height: 'calc(var(--vh-x, 1vh) * 100)',

    position: 'fixed',
    zIndex: 'panel',
    ...inset(0),
  },
  provider: {
    width: '100vw',
    height: '100%',

    '[data-themeui-nested-provider]': {
      width: '100%',
      height: '100%',

      pb: 1,
      bg: 'background',
      color: 'text',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  item: {
    p: '0.75em',

    borderWidth: 'var(--stroke-x)',
    borderStyle: 'solid',
    borderColor: 'currentColor',

    borderLeft: 'none',
    borderRight: 'none',

    '& + &': {
      borderTop: 'none',
    },

    display: 'flex',

    '&[data-highlight=true]': {
      bg: 'text',
      color: 'background',
    },

    '@media (hover: hover)': {
      '&:hover': {
        bg: 'text',
        color: 'background',
      },
    },

    '&[disabled]': {
      pointerEvents: 'none',
      '>span': {
        opacity: 0.35,
      },
    },
    '&.active': {
      bg: 'text',
      color: 'background',
    },
    transition: 'background-color var(--ui-duration) var(--ui-ease), color var(--ui-duration) var(--ui-ease)',
  },
  foot: {
    pt: 1,
    width: '100%',

    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridGap: '2em',

    alignContent: 'start',
    px: 1,
  },
}
