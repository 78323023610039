/** @jsxImportSource theme-ui */
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useClickOutside } from '@react-hookz/web'
import { Heading, Text } from '@monobits/components'
import ScrollLock from 'react-scrolllock'

import Box from '../Box'
import Button from '../Button'
import Loader from '../Loader'
import Icons from '../Icons'

import { modalObjectState } from '../../atoms'

/**
 * const { modal } = useData() ?? {}
 *
 * @example
 * modal.push({
 *   type: 'success/error/pending',
 *   title: 'Validating Transaction',
 *   message: ['This transaction is in process'],
 *   children: <span>JSX</span>,
 *   refs: [
 *     {
 *       name: 'Tx Hash',
 *       value: '5A0C20...A6B913',
 *       url: 'https://google.com',
 *     },
 *   ],
 * })
 *
 * @example modal.close()
 */
const Modal = ({ sx, ...props }) => {
  const [[action, args], setModalObject] = useRecoilState(modalObjectState)
  const {
    title = '',
    type = '',
    layout = 'simple',
    aside = null,
    message = [],
    refs = [],
    children = null,
  } = args ?? {}

  let ref = useRef(null)
  useClickOutside(ref, () => layout === 'complex' && setModalObject(['close', {}]))

  const openned = { open: true, close: false }[action]

  const cta = {
    back: (
      <Button onClick={() => setModalObject(['close', {}])} variant="primary">
        Back to page
      </Button>
    ),
    collection: (
      <Button onClick={() => setModalObject(['close', {}])} as={Link} to="/collection" variant="primary">
        My Collection
      </Button>
    ),
  }

  const content = {
    success: {
      icon: 'success',
      buttons: (
        <>
          {/* {cta.collection} */}
          {cta.back}
        </>
      ),
    },
    error: { icon: 'error', buttons: cta.back },
    pending: { icon: 'blank', buttons: null },
  }[type] || { icon: null, buttons: null }

  const view = {
    simple: (
      <div sx={{ variant: 'modal.container' }} data-column data-with-refs={!!refs?.length || false}>
        {!!content.icon && (
          <div sx={{ variant: 'modal.icon' }}>
            {type === 'pending' && <Loader enabled={true} configs={{ size: 22 }} inline={false} />}
            <Icons name={content.icon} variant="scale" themed />
          </div>
        )}

        {!!title && (
          <Heading variant="list" sx={{ variant: 'modal.title' }} cropped={false}>
            {title}
          </Heading>
        )}

        {!!message.length && (
          <div sx={{ variant: 'modal.text' }}>
            {message.map((string, i) => (
              <Text key={'modal-text' + i} cropped={false}>
                {string}
              </Text>
            ))}
          </div>
        )}

        {(children || content.buttons) && (
          <div sx={{ variant: 'modal.body' }} data-column>
            {children}
            {content.buttons}
          </div>
        )}

        {!!refs?.length && (
          <div sx={{ variant: 'modal.refs' }} data-column>
            {refs.map((obj, i) => (
              <RefItem key={'ref' + i} {...obj} />
            ))}
          </div>
        )}
      </div>
    ),
    complex: (
      <div sx={{ variant: 'modal.complex' }}>
        <Button
          sx={{ variant: 'modal.complex.close' }}
          variant="tertiary"
          aria-label="close"
          size="icons"
          onClick={() => setModalObject(['close', {}])}
        >
          <Icons name="cross" />
        </Button>

        {aside}

        <div sx={{ variant: 'modal.complex.container' }} data-column>
          <div data-column data-with-refs={!!refs?.length || false}>
            <Button
              sx={{ variant: 'modal.complex.hideMobile' }}
              variant="tertiary"
              aria-label="close"
              size="icons"
              onClick={() => setModalObject(['close', {}])}
            >
              <Icons name="cross" />
            </Button>

            {!!title && (
              <Heading variant="list" sx={{ variant: 'modal.title', textAlign: 'left', px: 0 }} cropped={false}>
                {title}
              </Heading>
            )}

            {!!message.length && (
              <div sx={{ variant: 'modal.text', textAlign: 'left', px: 0 }}>
                {message.map((string, i) => (
                  <Text key={'modal-text' + i} cropped={false}>
                    {string}
                  </Text>
                ))}
              </div>
            )}
          </div>
          {children && (
            <div sx={{ variant: 'modal.complex.body' }} data-column>
              {children}
            </div>
          )}
        </div>
      </div>
    ),
  }

  return openned ? (
    <div sx={{ variant: 'modal', ...sx }} data-type={type} {...props}>
      <ScrollLock isActive={openned} />

      <Box ref={ref} sx={{ variant: 'modal.wrapper' }} data-layout={layout} data-column>
        {view[layout]}
      </Box>
    </div>
  ) : null
}

function RefItem({ name, value, url, sx, ...props }) {
  return (
    <div sx={{ variant: 'modal.ref', ...sx }} {...props}>
      <h5>{name}</h5>
      {url ? (
        <a href={url} target="_blank" rel="noreferrer">
          {value}
          <span>
            <Icons name="external" variant="scale" />
          </span>
        </a>
      ) : (
        <span>{value}</span>
      )}
    </div>
  )
}

export default Modal
