import { createContext, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSafeState } from '@react-hookz/web'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { pick } from 'lodash'
import axios from 'axios'

import { useConnectionStatus } from './components/Wallet'
import { description, pluralize } from './content'
import { selectedListState, modalObjectState } from './atoms'

import { CONTRACT, DEFAULTS } from './__consts'

const { STAFF, STAGES } = DEFAULTS.STAGES_LEVEL_MAP

const DataContext = createContext()

const DataProvider = ({ children }) => {
  const query = useQuery()
  const { pathname } = useLocation()
  const [selected, setSelected] = useRecoilState(selectedListState)
  const setModalObject = useSetRecoilState(modalObjectState)

  const [data, setData] = useSafeState({})

  const ready = 'stages' in data

  const view = { staff: 'staff' }[query.get('view')] || 'stages'
  const all = ready ? data?.stages : {}
  const stages = ready ? { staff: pick(all, STAFF), stages: pick(all, STAGES) }[view] : []

  const currentProps = all?.[selected] ?? {}
  const nextAvailable = !!(+selected !== Object.keys(stages).length)
  const selectNext = nextAvailable ? () => setSelected((+selected + 1).toString()) : () => {}

  const connectionStatus = useConnectionStatus(currentProps, [all, stages])

  const stringLimit = pluralize(currentProps?.stage_user_limit || 1, 'Styllar')

  useEffect(() => {
    getDatas(setData)
  }, [pathname]) // eslint-disable-line

  return (
    <DataContext.Provider
      value={{
        ...data,
        ...connectionStatus,
        ready,
        currentProps: {
          ...currentProps,
          stringLimit,
          description: 'start_time' in currentProps ? description(currentProps, stringLimit) : '',
          min: DEFAULTS.QUANTITY,
        },
        stages,
        select: {
          selected,
          setSelected,
          nextAvailable,
          selectNext,
        },
        modal: {
          push: (args = {}) => setModalObject(['open', args]),
          update: (args = {}) => setModalObject((prev) => ['open', { ...prev[1], ...args }]),
          close: () => setModalObject(['close']),
        },
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

const source = `${CONTRACT.DOMAIN}/wasm/contracts/${CONTRACT.ADDRESS}`
async function getDatas(setter) {
  try {
    const res = await axios.get(source)
    const obj = res.data.result.init_msg
    const amount = parseInt(obj.sell_price.amount)

    setter({
      amount: [amount / 1_000_000, amount],
      stages: reduceStages(obj.stages),
    })
  } catch (error) {
    console.log(error)
  }
}

function reduceStages(array) {
  return array.reduce((acc, obj) => ({ ...acc, [obj.stage_level]: obj }), {})
}

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

/** Context Hook */
export const useData = () => useContext(DataContext)

export default DataProvider
