import { atom } from 'recoil'
import { DEFAULTS } from './__consts'

export const menuOpenState = atom({
  key: 'menuOpenState',
  default: false,
})

export const modalDisplayState = atom({
  key: 'modalDisplayState',
  default: false,
})

export const selectedListState = atom({
  key: 'selectedListState',
  default: DEFAULTS.WHITELIST,
})

export const selectedListStatus = atom({
  key: 'selectedListStatus',
  default: DEFAULTS.WHITELIST_STATUS,
})

export const currencyState = atom({
  key: 'currencyState',
  default: DEFAULTS.CURRENCY,
})

export const userDataRefreshCountState = atom({
  key: 'userDataRefreshCountState',
  default: 0,
})

export const modalObjectState = atom({
  key: 'modalObjectState',
  default: [
    '',
    {
      type: '',
      title: '',
      message: [],
      layout: 'simple',
      children: null,
      aside: null,
      refs: [],
    },
  ],
})
