export default {
  styles: {
    // p: '1em 1.5em',
    p: ['1.16em 1.75em', null, '0.92em 1.75em'],
    borderRadius: '200px',
    bg: 'white',

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text',
  },
  input: { variant: 'forms.styles' },
  select: {
    variant: 'forms.styles',
    '&+svg': {
      height: '2.5em',
      width: 'unset',

      ml: '-4.25em',

      alignSelf: 'center',
      pointerEvents: 'none',
    },
  },
  quantity: {
    '--disabled-opacity': 0.3,

    display: 'flex',
    gap: '0.5em',

    textAlign: 'center',
    '&[disabled]': {
      textAlign: 'left',
      opacity: 'var(--disabled-opacity)',
    },

    button: {
      variant: 'forms.styles',

      '&[disabled]': {
        opacity: 'var(--disabled-opacity)',
      },

      '&:active': {
        bg: 'primary',
        color: 'background',
      },
    },
    value: {
      variant: 'forms.styles',
      userSelect: 'none',
      flexGrow: 1,

      '>span': {
        minWidth: '3ch',
        display: 'inline-block',
      },
    },

    '[data-max]': { flexGrow: 3 },
    '[data-button]': {
      p: 0,
      size: '3.4em',
      position: 'relative',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      svg: {
        width: '1em',
        position: 'absolute',
      },
    },
  },
}
