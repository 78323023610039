/** @jsxImportSource theme-ui */
import { useRecoilState } from 'recoil'
// import { Link, NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { Text } from '@monobits/components'

import { Connect } from './Wallet'
import Button from './Button'
import Icons from './Icons'

import { menuOpenState } from '../atoms'

const Header = ({ minimal = false, sx, ...props }) => {
  const [openned, setOpenned] = useRecoilState(menuOpenState)

  return (
    <header sx={{ variant: 'header', ...sx }} {...props}>
      <Link to="/" sx={{ variant: 'header.logo' }}>
        <Icons name="logo" />
      </Link>
      <div sx={{ variant: 'header.navs' }}>
        <nav sx={{ variant: 'header.items' }}>
          {!minimal && (
            <>
              {/* <NavItem to="/fuser" className="-hide-mobile">
                Item Fuser
              </NavItem> */}
              <Connect>
                {({ connected, address, prompt }) => (
                  <Button.Wallet
                    variant={connected ? 'secondary' : 'primary'}
                    icon={connected ? <span data-bullet /> : null}
                    sx={{ width: '100%', justifyContent: 'space-between' }}
                    size="slim"
                  >
                    {connected ? address : prompt}
                  </Button.Wallet>
                )}
              </Connect>
            </>
          )}
          <BurgerLong active={!openned} onClick={() => setOpenned((prev) => !prev)} className="-show-desktop" />
          <Burger active={!openned} onClick={() => setOpenned((prev) => !prev)} className="-hide-desktop" />
        </nav>
      </div>
    </header>
  )
}

// function NavItem({ children, sx, ...props }) {
//   return (
//     <Button as={NavLink} variant="nav" size="slim" {...props}>
//       {children}
//     </Button>
//   )
// }
function Burger({ bg = 'text', active, sx, ...props }) {
  return (
    <button
      data-active={!!active}
      sx={{ variant: 'header.burger', ...sx }}
      data-gsap="burger"
      aria-label="menu"
      data-long={false}
      {...props}
    >
      <div data-fill />
    </button>
  )
}

function BurgerLong({ bg = 'text', active, sx, ...props }) {
  return (
    <Button
      variant="burger"
      size="slim"
      data-active={!!active}
      sx={{ variant: 'header.burger', ...sx }}
      data-gsap="burger"
      aria-label="menu"
      data-long={true}
      cropped={false}
      {...props}
    >
      <Text as="span">Menu</Text>
      <div data-fill />
    </Button>
  )
}

export default Header
