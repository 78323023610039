export default {
  dashboard: {
    display: 'grid',
    gridTemplateColumns: ['1fr', null, 'repeat(6, 1fr)'],
    gridTemplateRows: [null, null, 'auto 1fr'],
    gridGap: 1,
    pb: 'calc(var(--padding-x) * 2)',

    gridTemplateAreas: [
      '"time" "body" "side"',
      null,
      `"body body body body time time"
      "body body body body side side"`,
    ],

    '[data-area="body"]': { gridArea: 'body' },
    '[data-area="status"]': { gridArea: 'status' },
    '[data-area="time"]': { gridArea: 'time' },

    '[data-area="side"]': {
      gridArea: 'side',

      display: 'grid',
      gridTemplateColumns: ['1fr', '1fr 1fr', '1fr'],
      gridGap: 1,

      height: 'fit-content',
    },
  },
  fuser: {
    display: 'grid',
    gridTemplateColumns: ['1fr', null, 'repeat(7, 1fr)'],
    gridTemplateRows: ['1fr 1fr', null, '1fr'],
    gridGap: 1,
    pb: 1,

    '--height': 'calc((var(--vh-x, 1vh) * 100) - (var(--logo-y) + (var(--padding-x) * 2)))',

    height: 'var(--height)',
    maxHeight: 'var(--height)',
    minHeight: 0,

    '&[aria-expanded=true]': {
      '--template-areas': [
        '"side" "side"',
        null,
        `"body body body body side side side"
        "body body body body side side side"`,
      ],
      '--item-columns': ['repeat(3, 1fr)', 'repeat(auto-fill, minmax(17rem, 1fr))'],
      '[data-area="body"]': {
        display: ['none', null, 'flex'],
      },
    },
    '&[aria-expanded=false]': {
      '--template-areas': [
        '"body" "body"',
        null,
        `"body body body body body body body"
        "body body body body body body body"`,
      ],
      '--item-columns': ['repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(auto-fill, minmax(17rem, 1fr))'],
      '[data-area="side"]': {
        display: 'none',
      },
    },

    gridTemplateAreas: 'var(--template-areas)',

    '[data-area="body"]': {
      gridArea: 'body',
    },

    '[data-area="side"]': {
      gridArea: 'side',

      display: 'grid',
      gridTemplateColumns: '1fr',
      gridTemplateRows: 'auto 1fr',
      gridGap: 1,
    },
  },
  gallery: {
    py: 2,

    '&:not([data-view="all"]):not([data-view="airdrop"])': {
      '[data-gallery-items]': {
        display: 'grid',
        gridTemplateColumns: ['1fr 1fr', 'repeat(auto-fill, minmax(22rem, 1fr))'],
        gridGap: ['1.25em', 1],
        pt: 1,
        px: 1,
      },
    },
    '&[data-view="all"],&[data-view="airdrop"]': {
      '.infinite-scroll-component': {
        display: 'grid',
        gridTemplateColumns: ['1fr 1fr', 'repeat(auto-fill, minmax(22rem, 1fr))'],
        gridGap: ['1.25em', 1],
        pt: 1,
        px: 1,
      },
    },

    width: '100%',

    '[data-filters]': {
      gridColumn: '1/-1',
    },
  },
}
