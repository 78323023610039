/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'
import { Button as Component, Text } from '@monobits/components'
import Icons from './Icons'

const Button = ({
  icon,
  children,
  layout,
  sx,
  iconProps = {},
  font = { variant: 'text' },
  cropped = true,
  ...props
}) => {
  const alignment = icon ? 'withicon' : layout

  return (
    <Component sx={{ '&': { variant: setVariantString('button.layout', alignment) }, ...sx }} {...props}>
      <Box className="wrapper" {...iconProps}>
        {icon && icon}
      </Box>
      {cropped || typeof children === 'string' ? (
        <Text as="span" {...font}>
          {children}
        </Text>
      ) : (
        <span>{children}</span>
      )}
    </Component>
  )
}

const Wallet = ({ icon, ...props }) => <Button icon={icon ? icon : <Icons name="wallet" />} {...props} />

function setVariantString(key, value) {
  if (value != null) return key + '.' + value
  return key
}

Button.Wallet = Wallet

export default Button
