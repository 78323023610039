import { LCDClient } from '@terra-money/terra.js'

const NETWORK = 'MAINNET'

export const MAINNET = {
  name: 'mainnet',
  chainID: 'columbus-5',
  URL: 'https://lcd.terra.dev',
}
export const TESTNET = {
  URL: 'https://bombay-lcd.terra.dev',
  chainID: 'bombay-12',
}

export const NET = { MAINNET, TESTNET }[NETWORK]

export const getClient = () => new LCDClient(NET)

export const DENOM = {
  UST: 'uusd',
  LUNA: 'uluna',
}

export const DEFAULTS = {
  CURRENCY: 'UST',
  WHITELIST_STATUS: null,
  WHITELIST: '1',
  QUANTITY: 1,
  PRICE: 200,
  LOADER_TIMEOUT: 800,
  RESERVATIONS_REFRESH_RATE: 1000 * 15,
  LUNA_REFRESH_RATE: 1000 * 30,
  RANDOMEARTH_LINK: 'https://randomearth.io/collections/terra1934kn7p03ns94htl75zpzsg0n4yvw8yf2746ep',
  STAGES_LEVEL_MAP: {
    STAFF: ['0'],
    STAGES: ['1', '2', '3', '4'],
  },
}

export const CONTRACT = {
  TRANSACTION_URL: 'https://finder.terra.money/columbus-5/tx/',
  DOMAIN: 'https://lcd.terra.dev',
  DOMAIN_FCD: 'https://fcd.terra.dev',
  ADDRESS: 'terra1xe5n8aa8xvtqtwtaj6k9fu0kd209thyff3gfuk',
  NFT_ADDRESS: 'terra1934kn7p03ns94htl75zpzsg0n4yvw8yf2746ep',
  ITEMS_ADDRESS: 'terra1gek0c83grmj6twdr8c9al00hagd4kw5lxlt0cu',
  MINT_ROUTE: '/store?query_msg=%7B%22total%22:%7B%7D%7D',
}

export const walletConnectChainIds = {
  0: TESTNET,
  1: MAINNET,
}
