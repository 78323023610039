import { WalletProvider as Provider } from '@terra-money/wallet-provider'
import { TESTNET, MAINNET, walletConnectChainIds } from '../../__consts'

const WalletProvider = ({ children, ...props }) => (
  <Provider
    defaultNetwork={process.env.REACT_APP_ENV === 'dev' ? TESTNET : MAINNET}
    walletConnectChainIds={walletConnectChainIds}
    {...props}
  >
    {children}
  </Provider>
)

export default WalletProvider
