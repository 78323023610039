export const querify = (obj) =>
  '?' +
  Object.entries(obj)
    .map(([key, value]) => (!!value ? `${key}=${value}` : false))
    .filter(Boolean)
    .join('&')

export const stringQuery = (obj) => {
  const strictObject = Object.entries(obj).reduce((a, [k, v]) => (v == null ? a : ((a[k] = v), a)), {})
  return JSON.stringify(strictObject)
}

export const inset = (value) => {
  const styles = {
    number: {
      top: value,
      left: value,
      bottom: value,
      right: value,
    },
    string: {
      top: value,
      left: value,
      bottom: value,
      right: value,
    },
    array: {
      top: value[0],
      left: value[1],
      bottom: value[2],
      right: value[3],
    }[typeof value],
  }

  return {
    '@supports (inset: 0)': { inset: value },
    '@supports not (inset: 0)': styles,
  }
}
