import { transitions } from 'polished'

export default {
  hack: {
    close: {
      position: 'absolute',
      inset: 0,
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',

      bg: 'var(--bg)',
      borderRadius: '50%',
    },
  },
  indent: {
    __default: {
      display: 'inline-block',
      textIndent: 'var(--text-indent, 1em)',

      'b, strong': {
        color: 'primary',
        fontWeight: '300!important',
      },
    },
  },
  icon: { size: { '--stroke-absolute': (t) => t.borderWidths[1] + 'px' } },
  layout: {
    section: {
      regular: { my: 3 },
      heading: {
        mt: 3,
        mb: 2,
      },
    },
  },
  button: {
    styles: {
      fontSize: 1,
      textTransform: 'uppercase',
      '--disabled-opacity': 0.35,

      '&::before': {
        borderRadius: '200px',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'var(--border-color)',
      },
    },
    variants: {
      burger: {
        '--text': (t) => t.colors.burger_color,
        '--text-hover': (t) => t.colors.burger_color,
        '--bg': (t) => t.colors.burger_bg,
        '--bg-hover': (t) => t.colors.burger_bg,
        '--border-color': (t) => t.colors.burger_bg,

        '--b': (t) => t.colors.background,
      },
      primary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.primary,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.primary,

        '--b': (t) => t.colors.background,
      },
      secondary: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
      tertiary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.text,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
      subdued: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': 'transparent',

        '--b': 'transparent',

        '&[data-active=true],&.active': {
          '--text': (t) => t.colors.background,
          '--text-hover': (t) => t.colors.background,
          '--bg': (t) => t.colors.text,
          '--bg-hover': (t) => t.colors.text,
          '--border-color': 'transparent',
        },
      },
      ghost: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': 'transparent',
        '--bg-hover': 'transparent',
        '--border-color': 'transparent',

        '--b': 'transparent',
      },
      stroked: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.text,

        '--b': 'transparent',
      },
      outlined: {
        '--text': 'white',
        '--text-hover': 'white',
        '--bg': (t) => t.colors.error,
        '--bg-hover': (t) => t.colors.error,
        '--border-color': (t) => t.colors.error,

        '--b': 'transparent',
      },
      decline: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.error,
        '--border-color': 'transparent',

        '&._hover': {
          '--border-color': (t) => t.colors.error,
        },
      },
      ended: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.primary,
        '--border-color': (t) => t.colors.primary,

        '--b': 'transparent',
      },
      toggle: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': 'transparent',
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '&[data-active=true],&.active': {
          '--text': (t) => t.colors.background,
          '--text-hover': (t) => t.colors.background,
          '--bg': (t) => t.colors.primary,
          '--bg-hover': (t) => t.colors.text,
          '--border-color': (t) => t.colors.primary,

          '&._hover': {
            '--border-color': (t) => t.colors.text,
          },
        },

        '--b': 'transparent',
      },
      nav: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.background,
        '--bg': 'transparent',
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,

        '&[data-active=true],&.active': {
          '--text': (t) => t.colors.background,
          '--text-hover': (t) => t.colors.background,
          '--bg': (t) => t.colors.text,
          '--bg-hover': (t) => t.colors.text,
          '--border-color': (t) => t.colors.text,
        },

        '--b': 'transparent',
      },
      airdrop: {
        '--text': (t) => t.colors.orange,
        '--text-hover': (t) => t.colors.light,
        '--bg': 'transparent',
        '--bg-hover': (t) => t.colors.orange,
        '--border-color': (t) => t.colors.orange,

        '--icon-height': '3.5em',
        flexDirection: 'row!important',
        width: 'fit-content',

        '.wrapper': {
          ml: '-0.2em',
        },

        '&[data-active=true]': {
          '--text': (t) => t.colors.light,
          '--text-hover': (t) => t.colors.light,
          '--bg': (t) => t.colors.orange,
          '--bg-hover': (t) => t.colors.orange,
          '--border-color': (t) => t.colors.orange,
        },

        '--b': 'transparent',
      },
      airdropLabel: {
        '--text': (t) => t.colors.light,
        '--text-hover': (t) => t.colors.light,
        '--bg': (t) => t.colors.orange,
        '--bg-hover': (t) => t.colors.orange,
        '--border-color': (t) => t.colors.orange,

        '--icon-height': '3.5em',
        '--b': 'transparent',
      },
      inStockLabel: {
        '--text': (t) => t.colors.light,
        '--text-hover': (t) => t.colors.light,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.primary,
        '--border-color': (t) => t.colors.primary,
      },
      outOfStockLabel: {
        '--text': (t) => t.colors.light,
        '--text-hover': (t) => t.colors.light,
        '--bg': (t) => t.colors.error,
        '--bg-hover': (t) => t.colors.error,
        '--border-color': (t) => t.colors.error,
      },
      comingSoonLabel: {
        '--text': (t) => t.colors.dark,
        '--text-hover': (t) => t.colors.dark,
        '--bg': (t) => t.colors.yellow,
        '--bg-hover': (t) => t.colors.yellow,
        '--border-color': (t) => t.colors.yellow,
      },
      custom: {
        '--text': 'var(--text-color)',
        '--text-hover': 'var(--text-color)',
        '--bg': 'var(--bg-color)',
        '--bg-hover': 'var(--bg-color)',

        '::before': {
          bg: 'unset',
          background: 'var(--bg-color)',
          border: 'none',

          ...transitions(['background-color', 'opacity'], 'var(--speed, 0.45s) var(--ease, ease)'),
        },

        '&._hover::before': {
          opacity: 0.6,
        },
      },
    },
    sizes: {
      md: { p: ['1.5em 1.25em', null, '1.25em 1.15em'] },
      ssm: { p: ['0.5em 0.6em', null, '0.6em 0.6em'] },
      sm: { p: ['0.85em 1.25em', null, '0.6em 0.6em'] },
      smSquare: { p: ['0.85em 1em', null, '0.6em 0.75em'] },
      slim: { p: ['0.85em 1.25em', null, '1.05em 1.25em'] },
      icons: {
        p: ['1.17em', null, '1.4em'],
        svg: {
          position: 'absolute',
          height: '45%',
          top: '50%',
          left: '50%',

          transform: 'translate(-50%,-50%)',
        },
      },
      iconsMd: {
        p: ['1.85em', null, '1.6em'],

        svg: {
          position: 'absolute',
          height: '45%',
          top: '50%',
          left: '50%',

          transform: 'translate(-50%,-50%)',
        },
      },
      __default: { variant: 'button.sizes.md' },
    },
    layout: {
      __default: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
        justifyContent: 'flex-start',
      },
      round: {
        textAlign: 'center',
        borderRadius: '50%',
        size: 'var(--cta-size)',
      },
      withicon: {
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',

        gap: '1em',

        '>div': {
          display: 'inline-flex',
          alignItems: 'center',
        },
        'svg, .icon': {
          my: 'calc(var(--icon-height, 1em) * -1)',
          width: 'unset',
          height: 'var(--icon-height, 1em)',
        },
        '.wrapper': {
          height: 'var(--icon-height, 1em)',
          my: 'calc(var(--icon-height, 1em) * -1)',
        },
      },
    },
  },
}
