/** @jsxImportSource theme-ui */
import MenuItem, { SocialLink } from './Menu.Item'
import Component from './Menu.component'

const domain = 'https://app.styllar.com'

const Menu = () => {
  return (
    <Component
      navigation={[
        <MenuItem as="a" href={domain} children="Collection" />,
        <MenuItem as="a" href={domain + '/fuser'} children="Item Fuser™" />,
        <MenuItem as="a" href={domain + '/builder'} children="Avatar Builder™" disabled />,
        <MenuItem as="a" href={domain + '/shop'} children="Shop" disabled />,
        <MenuItem as="a" href={domain + '/about'} children="About Us" disabled />,
      ]}
      secondary={
        <nav sx={{ variant: 'header.socials' }}>
          <SocialLink name="medium" />
          <SocialLink name="instagram" />
          <SocialLink name="discord" />
          <SocialLink name="twitter" />
        </nav>
      }
    />
  )
}

export default Menu
