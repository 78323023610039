export default {
  __default: {
    minHeight: '18vw',
    justifyContent: 'space-between',

    '[data-themeui-nested-provider]': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },

    aside: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    svg: {
      width: 'unset',
      height: '1.5rem',
    },
    '&[data-active="false"] svg': {
      opacity: 0.2,
    },
  },
  title: { maxWidth: [null, null, '10ch'] },
  caption: { textAlign: 'right' },
  foot: {
    mt: 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  countdown: {
    position: 'relative',
    '[data-valid=false]': {
      opacity: 0,
    },
    '[data-mention]': {
      position: 'absolute',
      top: '-2em',
      left: '0',
      fontSize: '11px',
    },
  },
}
