export default {
  __default: {
    alignItems: 'center',

    '&[disabled]': {
      opacity: 0.35,
      pointerEvents: 'none',
      userSelect: 'none',
    },

    small: {
      mt: '1.25em',

      textAlign: 'center',
      fontWeight: '600',
      opacity: 0.3,
    },
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: 1,

    mb: 1,
    width: '100%',
  },
  button: {
    width: '100%',
    position: 'relative',

    '&[data-loader=true]': {
      pointerEvents: 'none',
      userSelect: 'none',
    },
  },
  field: {
    label: {
      mb: '1em',
      display: 'inline-block',
    },
    'label>span:first-of-type': { textTransform: 'uppercase' },
    'label>span:last-of-type': { fontSize: '75%', opacity: 0.3 },

    small: {
      mt: '1em',
      pr: '0.5em',
      width: '100%',
      textAlign: 'center',
      display: 'inline-block',
    },
  },
}
