import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { omit } from 'lodash'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

export default merge(omit(preset, ['colors']), {
  config,
  colors,

  breakpoints: ['43em', '62em', '82em'],

  styles: {
    root: {
      ...styles,
      ...rootFontStyles,
      ...rootScaleStyles,

      '--gradient':
        'linear-gradient(45.35deg, #EA2B2A 13.83%, #FB8001 29.57%, #13B537 44.53%, #01C5C2 57.04%, #011EFA 69.63%, #CC01FF 86.21%)',
      '--gradient-short': 'linear-gradient(95.48deg, #00AAFF -7.42%, #F6CE22 53.23%, #FF00FE 111.97%)',

      '--common': '#999999',
      '--rare': '#0086FF',
      '--epic': '#B866FF',
      '--legendary': '#FF9100',
      '--ultra-rare': '#FF0000',
      '--impossible': 'linear-gradient(91.28deg, #2FFF2F 0.25%, #E8FF5A 50.62%, #2FFF2F 99.41%)',
      '--unique': 'linear-gradient(91.28deg, #FF00FE 0.25%, #F6CE22 50.62%, #00AAFF 99.41%)',

      '--icon-root-width': '0.6rem',
      '--icon-height': '2em',
      '--icon-height-alt': ['3.2em', '2.25em'],
      '--icon-width': '1.25em',

      '--ui-duration': '0.45s',
      '--ui-ease': 'ease',

      '--input-padding': '1em',
    },
  },

  ...fonts,
  ...scales,
  ...overrides,
  ...variants,
})
