/** @jsxImportSource theme-ui */
import { useEffect } from 'react'

import Box from '../Box'
import Button from '../Button'
import Loader from '../Loader'
import useFormActions from './useFormActions'
import { useData } from '../../DataProvider'
import { Field, Select, Quantity } from '../Form'
import { DENOM } from '../../__consts'

const Transaction = ({ disabled = true, sx, ...props }) => {
  const currencies = Object.keys(DENOM)
  const {
    amount,
    select: { selected },
    currentProps: { min },
    user: { max_acc_mint },
  } = useData()

  const {
    price,
    loader,
    handleSubmit,
    quantity: [quantity, setQuantity],
    currency: [currency, setCurrency],
  } = useFormActions(amount)

  useEffect(() => {
    setQuantity(min)
  }, [selected]) // eslint-disable-line

  return (
    <Box variant="outline" sx={{ variant: 'transaction', ...sx }} data-column disabled={disabled} {...props}>
      <div sx={{ variant: 'transaction.fields' }}>
        <Field
          label="Quantity"
          caption="How many NFT’s to mint?"
          info={`Max ${max_acc_mint} mints in this transaction, you have (${
            max_acc_mint <= 0 ? 0 : max_acc_mint - quantity
          }) left`}
        >
          <Quantity min={min} max={max_acc_mint} defaultValue={min} state={[quantity, setQuantity]} />
        </Field>
        <Field label="Currency" caption="What currency would you like to spend?">
          <Select defaultValue={currency} onChange={e => setCurrency(e.target.value)}>
            {currencies.map((item, i) => (
              <option key={item + i} value={item}>
                {item}
              </option>
            ))}
          </Select>
        </Field>
      </div>

      <Button sx={{ variant: 'transaction.button' }} data-loader={loader} onClick={!disabled ? handleSubmit : null}>
        <Loader enabled={loader}>
          {quantity} Mint for {price} {currency}
        </Loader>
      </Button>
    </Box>
  )
}

export default Transaction
