import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'
import WalletProvider from './Wallet/WalletProvider'
import DataProvider from '../DataProvider'

const Providers = ({ children }) => (
  <BrowserRouter>
    <HelmetProvider>
      <RecoilRoot>
        <WalletProvider>
          <DataProvider>{children}</DataProvider>
        </WalletProvider>
      </RecoilRoot>
    </HelmetProvider>
  </BrowserRouter>
)

export default Providers
