export const pluralize = (num, string) => `${num} ${string}${num > 1 ? 's' : ''}`

export const links = {
  discord: 'https://discord.gg/styllar',
  twitter: 'https://twitter.com/STYLLARNFT',
  instagram: 'https://www.instagram.com/styllarnft',
  medium: 'https://styllar.medium.com',
}

export const cta = {
  '/collection': {
    href: 'https://randomearth.io/collections/terra1934kn7p03ns94htl75zpzsg0n4yvw8yf2746ep',
    children: 'Marketplace',
    target: '_blank',
  },
  '/': {
    to: '/collection',
    children: 'Collection',
  },
}

export const times = [
  [
    'secondary',
    'Upcoming Stage',
    <>
      Stay tuned for the next stage
      <br />
      (days, hours, minutes, seconds)
    </>,
    'Time until start',
  ],
  [
    'primary',
    'Now Live:',
    <>
      Stage is now live!
      <br />
      (days, hours, minutes, seconds)
    </>,
    'Time until end',
  ],
  ['tertiary', 'Stage Completed', 'Stay tuned for next stage', 'Go to next stage'],
  ['tertiary', 'Sold Out', 'Thanks for your participation', 'Collection'],
]

export const connect = {
  prompt: 'Connect Terra Wallet',
}

export const collection = {
  heading: 'Welcome to your collection of minted Styllars.',
  caption: 'Collection',
  title: 'Collection | Styllar™',
}

export const statuses = {
  allowed: 'Congratulations, you made it to this stage!',
  completed: "You have reached this stage's limit.",
  prevented: 'You are not registered on this stage.',
  loading: 'Please wait...',
  connect: 'Please connect your wallet to verify',
  closed: 'This stage is now complete, continue to the other stages!',
  ended: 'You can see the marketplace now!',
}

export const description = (props, stringLimit) => (
  <>
    <p>
      <b>{formatedDates(props.start_time, props.end_time)}</b>
    </p>
    <p>
      Congratulations to those who made the <b>{props?.name}!</b> You are able to mint <b>{stringLimit}</b> during this
      stage.
    </p>
    <p>
      You'll receive your Styllar Avatar straight away; any additional items will be airdroped in the following week.
    </p>
    <blockquote>
      If you mint 1 during the first stage, you will still be able to mint 1 during the community whitelist stage two
      and 2 during the public whitelist stage for a total of 4. You will also (potentially if not sold out) still be
      able to mint up to 25 Styllars on the public sales stage for a total of 29 NFTs.
    </blockquote>
  </>
)

export const formatedDates = (start, end) => {
  const locale = 'en-US'
  const range = [start, end].map((i) => new Date(i * 1000))

  const dateString = new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'short', day: 'numeric' })
  const hourString = new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' })

  const hours = range
    .map((i) => hourString.format(i))
    .map((i) => i.replace(' ', ''))
    .join(' - ')

  return `${dateString.format(range[0])}. ${hours} EST`
}
