/** @jsxImportSource theme-ui */
import { forwardRef } from 'react'
import { Box as Base } from 'theme-ui'
import InvertedMode from './InvertedMode'

const Box = forwardRef(({ children, head, dense = false, variant, scroll = '', sx, ...props }, ref) => {
  const Component = variant === 'primary' ? InvertedMode : Base

  return (
    <Component
      ref={ref}
      as="section"
      sx={{ variant: variant ? `box.${variant}` : 'box', '&': { variant: head ? 'box.titled' : 'box.styles' }, ...sx }}
      {...props}
      data-scroll={scroll}
      data-dense={!!dense || !!head}
    >
      {head ? (
        <>
          <header>{head}</header>
          <article data-scroll={scroll}>{children}</article>
        </>
      ) : (
        children
      )}
    </Component>
  )
})

export default Box
