/** @jsxImportSource theme-ui */
import { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useTween, willChangeEvents } from '@monobits/gsap'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'
import ScrollLock from 'react-scrolllock'

import Header from '../Header'
import InvertedMode from '../InvertedMode'
import { menuOpenState } from '../../atoms'

const Menu = ({ navigation, secondary }) => {
  const { pathname } = useLocation()
  const [openned, setOpenned] = useRecoilState(menuOpenState)
  const breakpoint = useBreakpointIndex()

  const [tween, element] = useTween(
    ({ gsap, node }) => {
      return gsap
        .timeline({
          paused: true,
          defaults: {
            duration: 0.8,
            ease: 'expo.inOut',
            ...willChangeEvents(node),
          },
        })
        .set(node, { visibility: 'visible' })
        .to(node, { '--clip-path': '0%' })
    },
    ['container']
  )

  useConditionalEffect(
    () => {
      if (openned) tween.play()
      if (!openned) tween.reverse()
    },
    [openned],
    [tween],
    undefined,
    useUpdateEffect
  )

  useUpdateEffect(() => {
    setOpenned(false)
  }, [pathname])

  return (
    <section ref={element.ref} sx={{ variant: 'menu' }}>
      <InvertedMode data-column sx={{ variant: 'menu.provider' }}>
        <Header minimal />

        <ScrollLock isActive={breakpoint >= 2 ? false : !!openned}>
          <nav data-column sx={{ height: '100%' }}>
            {navigation.map((node, i) => (
              <Fragment key={i + 'menu'}>{node}</Fragment>
            ))}
          </nav>
        </ScrollLock>

        {secondary && <aside sx={{ variant: 'menu.foot' }}>{secondary}</aside>}
      </InvertedMode>
    </section>
  )
}

export default Menu
