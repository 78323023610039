/** @jsxImportSource theme-ui */
import { Container } from '@monobits/components'

import Box from '../components/Box'
import Time from '../components/Time'
import Meter from '../components/Meter'
import Status from '../components/Status'
import Toggles from '../components/Toggles'
import Description from '../components/Description'
import { Transaction } from '../components/Transaction'
import { useData } from '../DataProvider'

const IndexPage = () => {
  const {
    total_reservations,
    total_supply,
    user: {
      ready,
      connected,
      allowedActions,
      minted_by_user,
      max_mint,
      check_whitelist,
      //
    },
  } = useData() ?? {}

  return (
    <Container sx={{ variant: 'grids.dashboard' }}>
      <Box data-area="body">
        <Toggles disabled={!connected} />
        <Status />

        <Description />

        <Transaction disabled={!allowedActions} />
      </Box>

      <Time data-area="time" active={true} />

      <article data-area="side">
        <Meter
          text="You have minted"
          values={[minted_by_user, max_mint]}
          infinite={!check_whitelist}
          disabled={!ready}
        />
        <Meter text="Total minted" values={[total_reservations || 0, total_supply || 1]} />
      </article>
    </Container>
  )
}

export default IndexPage
