import { Sizes } from '@monobits/components'
import { ThemeProvider, Box } from 'theme-ui'
import { Routes, Route } from 'react-router-dom'
import theme from './theme'

import Menu from './components/Menu'
import Header from './components/Header'
import Modal from './components/Modal'
import AppLoader from './components/AppLoader'
import { useData } from './DataProvider'

import Index from './pages/Index'

const App = () => {
  const { ready } = useData() ?? {}

  return (
    <ThemeProvider theme={theme}>
      <Sizes />

      <Modal />
      <Menu />
      <Header />

      <AppLoader conditions={[!!ready]}>
        <Box as="main">
          <Routes>
            <Route exact path="/" element={<Index />} />
          </Routes>
        </Box>
      </AppLoader>
    </ThemeProvider>
  )
}

export default App
