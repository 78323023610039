/** @jsxImportSource theme-ui */
import { Heading, Button as Base } from '@monobits/components'
import { links } from '../../content'
import Icons from '../Icons'

const MenuItem = ({ children, highlight = false, sx, ...props }) => (
  <Heading variant="menu" data-highlight={!!highlight} sx={{ variant: 'menu.item', ...sx }} {...props}>
    <span>{children}</span>
  </Heading>
)

export const SocialLink = ({ name, ...props }) => (
  <Base as="a" href={links?.[name]} target="_blank" variant="secondary" size="iconsMd" {...props}>
    <Icons name={name} />
  </Base>
)

export default MenuItem
