/** @jsxImportSource theme-ui */
import { Connect } from './Wallet'
import Button from './Button'

import { useData } from '../DataProvider'
import { statuses } from '../content'
import { DEFAULTS } from '../__consts'

const Status = ({ sx, ...props }) => {
  const {
    user: { ready, stageStatus: type, check_whitelist = true },
    list: { status },
    event: { ended },
  } = useData() ?? {}

  const check = [type, type, 'closed', 'ended'][ended ? 3 : status || 0]

  return (
    (check_whitelist || ended) && (
      <aside sx={{ variant: `status.${ready ? check : type}`, ...sx }} {...props}>
        {ended ? (
          <>
            <span>{statuses[type]}</span>
            <Button variant="ended" as="a" href={DEFAULTS.RANDOMEARTH_LINK} target="_blank">
              Go to Marketplace
            </Button>
          </>
        ) : type === 'connect' ? (
          <>
            <span>{statuses[type]}</span>
            <Connect as={Button.Wallet}>{({ connected, address, prompt }) => (connected ? address : prompt)}</Connect>
          </>
        ) : (
          statuses[check]
        )}
      </aside>
    )
  )
}

export default Status
