/** @jsxImportSource theme-ui */
import { SpinnerCircular as Spinner } from 'spinners-react'
import { inset } from '../utils'

const BASE = {
  size: 18,
  thickness: 125,
  speed: 300,
  color: 'rgba(255, 255, 255, 1)',
  secondaryColor: 'none',
  style: { position: 'absolute' },
}

const Loader = ({ children, enabled = false, configs = {}, inline = true, sx, ...props }) => {
  const options = { ...BASE, ...configs }

  return enabled ? (
    <>
      <span
        sx={{
          position: 'absolute',
          ...inset(0),

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...sx,
        }}
        {...props}
      >
        <Spinner {...options} />
      </span>
      {inline && <span sx={{ visibility: 'hidden' }}>loading...</span>}
    </>
  ) : (
    <span sx={sx} {...props}>
      {children}
    </span>
  )
}

export default Loader
