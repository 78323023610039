/** @jsxImportSource theme-ui */
import Button from '../Button'
import { useData } from '../../DataProvider'

const Toggles = ({ disabled = true }) => {
  const {
    stages,
    select: { selected, setSelected },
  } = useData() ?? {}

  const buttons =
    stages &&
    Object.entries(stages).map(([key, { name, start_time }]) => (
      <Button
        key={start_time + key}
        variant="toggle"
        sx={{ variant: 'toggles.button' }}
        onClick={() => setSelected(key)}
        data-active={!!(key === selected)}
        disabled={disabled}
      >
        {name}
      </Button>
    ))
  return <div sx={{ variant: 'toggles' }}>{buttons}</div>
}

export default Toggles
