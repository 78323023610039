/** @jsxImportSource theme-ui */
import Icons from '../Icons'

const Quantity = ({ min, max, step = 1, defaultValue = 0, state = [], sx, ...props }) => {
  const [value, setValue] = state ?? []

  const disabled = !!!(max - 1)
  const great = max >= 4

  const fill = () => setValue(max)
  const increment = () => setValue(prev => (prev >= max ? max : prev + step))
  const decrement = () => setValue(prev => (prev <= min ? min : prev - step))

  const buttons = !disabled
    ? [
        <button onClick={decrement} data-button disabled={value === min}>
          <Icons name="minus" />
        </button>,
        <button onClick={increment} data-button disabled={value === max}>
          <Icons name="plus" />
        </button>,
        great && (
          <button onClick={fill} data-max disabled={value === max}>
            Add Max
          </button>
        ),
      ].filter(Boolean)
    : []

  return (
    <div sx={{ variant: 'forms.quantity', ...sx }} disabled={disabled} {...props}>
      {great && buttons?.[0]}
      <div sx={{ variant: 'forms.quantity.value' }}>
        <span>{value}</span>
      </div>
      {!great && buttons?.[0]}
      {buttons?.[1]}
      {buttons?.[2]}
    </div>
  )
}

export default Quantity
