import { transparentize, easeInOut } from 'polished'

const mediaClasses = (display, { prepend = true } = {}) => {
  const pre = !prepend ? '' : '-' + display
  return {
    ['.-hide-mobile' + pre]: { display: ['none', display] },
    ['.-hide-tablet' + pre]: { display: [null, 'none', display] },
    ['.-hide-desktop' + pre]: { display: [null, null, 'none'] },
    ['.-show-mobile' + pre]: { display: [null, 'none'] },
    ['.-show-tablet' + pre]: { display: ['none', display, 'none'] },
    ['.-show-desktop' + pre]: { display: ['none', null, display] },
  }
}

export default {
  ...mediaClasses('grid'),
  ...mediaClasses('block'),
  ...mediaClasses('flex', { prepend: false }),

  '[data-column]': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.-aspect-ratio, [data-aspect-ratio]': {
    aspectRatio: 'unset!important',
    '::after': {
      content: '""',
      pb: 'calc(100% / (var(--aspect-ratio)))',
      display: 'flex',
    },
  },

  '[data-underline]': {
    '--to': t => transparentize(1, t.rawColors.text),
    '--from': t => t.rawColors.text,

    '--stroke': '0.02em',
    '--spacing': '88%',

    textDecoration: 'none',
    backgroundImage: 'linear-gradient(var(--from), var(--from)), linear-gradient(var(--to), var(--to))',
    backgroundPosition: '100% var(--spacing), 0 var(--spacing)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% var(--stroke), 0 var(--stroke)',
    transition: `background-size var(--ui-duration) ${easeInOut('cubic')}`,

    '@media (hover: hover)': {
      '&:hover': {
        backgroundSize: '0 var(--stroke), 100% var(--stroke)',
      },
    },
  },
}
