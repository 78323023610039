/** @jsxImportSource theme-ui */
import { Box } from 'theme-ui'

import Button from '../Button'
import { useData } from '../../DataProvider'
import { connect as content } from '../../content'

const Connect = ({ children, as, ...props }) => {
  const {
    modal,
    cropAddress,
    user: { connected, disconnect, connect, address },
  } = useData() ?? {}

  const connectWallet = (value = '') => {
    connect(value)
    setTimeout(() => modal.close(), 500)
  }

  const handleClick = connected
    ? () => disconnect()
    : () =>
        modal.push({
          title: 'Connect your wallet',
          children: (
            <>
              <Button onClick={() => connectWallet('EXTENSION')}>Connect with Extension</Button>
              <Button onClick={() => connectWallet('WALLETCONNECT')}>Connect with Mobile</Button>
            </>
          ),
        })

  const Component = as ? as : Box

  return (
    <Component data-connected={connected} onClick={handleClick} {...props}>
      {typeof children === 'function'
        ? children({
            connected,
            address: address && cropAddress(address),
            ...content,
          })
        : children}
    </Component>
  )
}

export default Connect
