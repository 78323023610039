export default {
  __default: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: '1em',

    '&[disabled]': {
      opacity: 0.35,
      pointerEvents: 'none',
      userSelect: 'none',
    },

    '--border': 'var(--stroke-x) solid currentColor',
    '--radius': '200px',
  },
  head: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '1em',
    mt: 1,

    '>:nth-of-type(1)': { justifySelf: 'start' },
    '>:nth-of-type(2)': { justifySelf: 'center' },
    '>:nth-of-type(3)': { justifySelf: 'end' },

    '&[aria-hidden=true]': {
      visibility: 'hidden',
    },
  },
  indicator: {
    border: 'var(--border)',
    borderRadius: 'var(--radius)',

    height: '3rem',

    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',

    '>div': {
      '--offset': '10px',

      clipPath: 'inset(0 calc(100% - var(--progress, 0%)) 0 0 round 100px)',
      width: 'calc(100% + var(--offset))',
      height: 'calc(100% + (var(--stroke-x) * 2))',

      transition: 'clip-path var(--ui-duration) var(--ui-ease)',

      position: 'absolute',
      left: 'calc((var(--offset) / 2) * -1)',

      borderTopRightRadius: 'var(--radius)',
      borderBottomRightRadius: 'var(--radius)',

      border: 'var(--border)',
      borderLeft: 'none',

      background: 'var(--gradient-short)',
    },
  },
}
