import { Icon } from '@monobits/components'

import ArrowDown from '../assets/svg/arrow-down.svg'
import Cross from '../assets/svg/cross.svg'
import CrossY from '../assets/svg/cross-y.svg'
import CrossSquare from '../assets/svg/cross-square.svg'
import Plus from '../assets/svg/plus.svg'
import Minus from '../assets/svg/minus.svg'
import Discord from '../assets/svg/discord.svg'
import Instagram from '../assets/svg/instagram.svg'
import Medium from '../assets/svg/medium.svg'
import Logo from '../assets/svg/logo.svg'
import Signal from '../assets/svg/signal.svg'
import Twitter from '../assets/svg/twitter.svg'
import Wallet from '../assets/svg/wallet.svg'
import External from '../assets/svg/external.svg'
import Luna from '../assets/svg/luna.svg'
import Search from '../assets/svg/search.svg'
import More from '../assets/svg/more.svg'
import Styllar from '../assets/svg/styllar.svg'
import Items from '../assets/svg/items.svg'
import Airdrop from '../assets/svg/airdrop.svg'
import AirdropY from '../assets/svg/airdrop-y.svg'

import HeadSuccess from '../assets/svg/success.svg'
import HeadError from '../assets/svg/error.svg'
import HeadBlank from '../assets/svg/blank.svg'

const icons = {
  'arrow-down': ArrowDown,
  'cross-y': CrossY,
  'cross-square': CrossSquare,
  cross: Cross,
  plus: Plus,
  minus: Minus,
  discord: Discord,
  instagram: Instagram,
  medium: Medium,
  logo: Logo,
  signal: Signal,
  twitter: Twitter,
  wallet: Wallet,
  external: External,
  luna: Luna,
  search: Search,
  more: More,
  avatars: Styllar,
  items: Items,
  airdrop: Airdrop,
  'airdrop-y': AirdropY,

  success: HeadSuccess,
  error: HeadError,
  blank: HeadBlank,
}

const Icons = ({ name, themed = false, ...props }) => {
  const Component = themed ? Icon.Themed : Icon

  return name in icons && <Component icon={icons[name]} {...props} />
}

export default Icons
