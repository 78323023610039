export default {
  styles: {
    p: 'var(--p)',
    borderRadius: (t) => t.space?.[1],

    '&[data-dense=true]': { '--p': '1.35rem' },
    '&[data-dense=false]': { '--p': ['var(--padding-x)', null, '2rem'] },
  },
  titled: {
    borderRadius: (t) => t.space?.[1],

    '&[data-dense=true]': {
      '--p': '1.35rem',
    },

    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '&[data-scroll="vertical"]': { minHeight: 0 },
    '&[data-scroll="horizontal"]': { minWidth: 0 },

    '>header': {
      py: 'calc(var(--p) * 0.65)',
      px: 'var(--p)',
      minHeight: '4.5em',

      borderBottom: (t) => `var(--stroke-x) solid ${t.colors?.subdued}`,

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      gap: 1,
      '>div': {
        display: 'flex',
        gap: '0.6em',
      },
    },
    '>article': {
      p: 'var(--p)',

      '&[data-scroll="vertical"]': {
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        WebkitOverflowScrolling: 'touch',

        backfaceVisibility: 'hidden',
        transform: 'translateZ(0)',
      },
      '&[data-scroll="horizontal"]': {
        overflowY: 'hidden',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
      },
    },
  },

  __default: { variant: 'box.basic' },
  basic: {
    bg: 'white',
  },
  primary: {
    '[data-themeui-nested-provider]': {
      bg: 'background',
      color: 'text',
    },
  },
  secondary: {
    bg: 'yellow',
  },
  tertiary: {
    color: 'orange',
    border: 'var(--stroke-x) solid currentColor',
  },
  outline: {
    bg: 'white',
    border: 'var(--stroke-x) solid currentColor',
  },
}
