/** @jsxImportSource theme-ui */
import { useRecoilValue } from 'recoil'
import { Heading, Text } from '@monobits/components'

import { useData } from '../DataProvider'
import { currencyState } from '../atoms'

const Description = ({ sx, ...props }) => {
  const currency = useRecoilValue(currencyState)
  const {
    amount,
    currentProps: { description, stringLimit },
  } = useData() ?? {}

  return (
    <section sx={{ variant: 'description', ...sx }} {...props}>
      <div sx={{ variant: 'description.blocks' }}>
        <Block caption="Styllars available to mint in this stage">{stringLimit}</Block>
        <Block caption="Price per Styllar">
          ${amount?.[0]} / {{ UST: 'UST', LUNA: 'LUNA equivalent' }[currency]}
        </Block>
      </div>
      <div sx={{ variant: 'description.rte' }}>{description}</div>
    </section>
  )
}

function Block({ children, caption, ...props }) {
  return (
    <div sx={{ variant: 'description.block' }} {...props}>
      <Heading as="h3" variant="caption">
        {caption}
      </Heading>
      <Text as="div" variant="list">
        {children}
      </Text>
    </div>
  )
}

export default Description
