export default {
  __default: {
    '--gap-rte': 'var(--rte)',

    my: '3rem',
  },
  blocks: {
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 1fr'],
    gridGap: 1,
  },
  block: {
    borderTop: 'var(--stroke-x) solid currentColor',
    pt: '1em',
    '>div': { pt: 1 },
  },
  rte: {
    mt: [1, '4rem'],
    '&>* + *': { mt: 'var(--gap-rte)' },

    'b, strong': {
      fontWeight: 600,
    },

    blockquote: {
      opacity: 0.7,
      py: '0.25em',
      pl: '1em',
      borderLeft: 'var(--stroke-x) solid currentColor',
    },
  },
}
