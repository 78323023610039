import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { useSafeState, useUpdateEffect, useConditionalEffect } from '@react-hookz/web'
import axios from 'axios'

import useSubmission from './useSubmission'
import { currencyState } from '../../atoms'
import { DENOM, DEFAULTS, CONTRACT } from '../../__consts'

const LOADER = {
  BUFFER: 100,
  DURATION: 750,
}

const useFormActions = (amount = [DEFAULTS.PRICE, 0]) => {
  const { send } = useSubmission()

  const [priceStep] = amount ?? []

  const [price, setPrice] = useSafeState(DEFAULTS.PRICE)
  const [quantity, setQuantity] = useState(DEFAULTS.QUANTITY)
  const [currency, setCurrency] = useRecoilState(currencyState)
  const [loader, setLoader] = useState(false)

  const [details, setDetails] = useSafeState({})
  const [gasPrices, setGasPrices] = useSafeState([])

  const handleSubmit = () => {
    const denom = DENOM[currency]
    if (denom in gasPrices) {
      const gasPrice = gasPrices[denom]
      send({
        denom,
        gasPrice,
        msgProps: [
          {
            purchase_nft: { requested_reservations: quantity },
          },
          details,
        ],
      })
    } else {
      alert('Error getting the gas price!')
    }
  }

  useEffect(() => loadGasPrices().then((res) => setGasPrices(res.data)), []) // eslint-disable-line

  useEffect(() => {
    const oneMillion = 1_000_000
    const ustPrice = quantity * priceStep
    const ustRawPrice = ustPrice * oneMillion

    const setUST = () => {
      setPrice(ustPrice)
      setDetails({ uusd: ustRawPrice })
    }
    const setLUNA = () => {
      setTimeout(
        () =>
          getULunaPrice(ustRawPrice).then((res) => {
            const uLunaPrice = parseInt(res.data.result.amount)
            setPrice(uLunaPrice / oneMillion)
            setDetails({ uluna: uLunaPrice })
          }),
        LOADER.BUFFER
      )
    }

    if (currency === 'UST') setUST()
    if (currency === 'LUNA') {
      setLUNA()
      const interval = setInterval(() => setLUNA(), DEFAULTS.LUNA_REFRESH_RATE)
      return () => clearInterval(interval)
    }
  }, [quantity, currency, priceStep]) // eslint-disable-line

  useConditionalEffect(
    () => {
      setLoader(true)
      setTimeout(() => setLoader(false), LOADER.DURATION)
    },
    [quantity, currency],
    [currency === 'LUNA'],
    undefined,
    useUpdateEffect
  )

  function getULunaPrice(value) {
    return axios.get(`${CONTRACT.DOMAIN}/market/swap?offer_coin=${value}uusd&ask_denom=${DENOM.LUNA}`)
  }

  function loadGasPrices() {
    return axios.get(`${CONTRACT.DOMAIN_FCD}/v1/txs/gas_prices`)
  }

  return {
    price,
    quantity: [quantity, setQuantity],
    currency: [currency, setCurrency],
    handleSubmit,
    loader,
  }
}

export default useFormActions
