import { tint } from 'polished'

export default {
  styles: {
    variant: 'box.styles',
    display: 'flex',
    fontSize: 'var(--size-status)',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    mb: 1,
  },
  allowed: {
    variant: 'status.styles',
    bg: (t) => tint(0.8, t.rawColors.blue),
    color: 'blue',
  },
  completed: {
    variant: 'status.styles',
    bg: (t) => tint(0.8, t.rawColors.yellow),
    color: 'dark',
  },
  closed: {
    variant: 'status.prevented',
  },
  ended: {
    variant: 'status.connect',

    // bg: (t) => tint(0.8, t.rawColors.yellow),
    color: 'primary',
    borderColor: (t) => t.colors.primary,
  },
  loading: {
    variant: 'status.styles',

    '--color': (t) => tint(0.8, t.rawColors.text),

    border: 'var(--stroke-x) solid var(--color)',
    color: 'var(--color)',
    bg: 'white',
  },
  prevented: {
    variant: 'status.styles',
    bg: (t) => tint(0.8, t.rawColors.orange),
    color: 'orange',
  },
  connect: {
    variant: 'status.styles',
    textAlign: 'left',

    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
    gap: 1,

    button: {
      my: [null, '-0.5em', '-1em'],
    },

    '--color': (t) => tint(0.8, t.rawColors.text),

    border: 'var(--stroke-x) solid var(--color)',
    color: 'var(--color)',
    bg: 'white',
  },
}
