import { Select as Component } from 'theme-ui'
import Icons from '../Icons'

const Select = ({ children, arrow, ...props }) => (
  <Component arrow={arrow ? arrow : <Icons name="arrow-down" />} {...props}>
    {children}
  </Component>
)

export default Select
