import { Box, ThemeProvider, useThemeUI } from 'theme-ui'
import { omit } from 'lodash'

const invertedTheme = (colors) => {
  const rest = omit(colors.modes, ['light', 'dark'])

  return {
    colors: {
      ...colors.modes?.dark,
      modes: {
        light: colors.modes?.dark,
        dark: colors.modes?.light,
        ...rest,
      },
    },
  }
}

const InvertedMode = ({ children, ...props }) => {
  const { rawColors } = useThemeUI().theme ?? {}

  return (
    <Box {...props}>
      <ThemeProvider theme={invertedTheme(rawColors)}>{children}</ThemeProvider>
    </Box>
  )
}
export default InvertedMode
