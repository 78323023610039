/** @jsxImportSource theme-ui */
import { useConditionalEffect } from '@react-hookz/web'
import { useState } from 'react'
import { inset } from '../utils'
import Loader from './Loader'

import { DEFAULTS } from '../__consts'

const AppLoader = ({ children, conditions = [] }) => {
  const [mount, setMount] = useState(false)

  useConditionalEffect(() => setTimeout(() => setMount(true), DEFAULTS.LOADER_TIMEOUT), undefined, conditions)

  return mount ? (
    children
  ) : (
    <div
      sx={{
        bg: 'background',
        position: 'fixed',
        ...inset(0),
        zIndex: 'modal',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div sx={{ opacity: 1 }}>
        <Loader
          enabled={true}
          configs={{ size: 32, thickness: 100, color: 'var(--theme-ui-colors-primary)' }}
          inline={false}
        />
      </div>
    </div>
  )
}

export default AppLoader
