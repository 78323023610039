import { transparentize } from 'polished'
import { inset } from '../../utils'

export default {
  __default: {
    bg: 'overlay',

    position: 'fixed',
    zIndex: 'popup',
    ...inset(0),

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&[data-type="success"]': { '--b': (t) => t.colors.yellow },
    '&[data-type="error"]': { '--b': (t) => t.colors.orange },
    '&[data-type="pending"]': { '--b': (t) => t.colors.primary },

    '&[aria-hidden=true]': { display: 'none' },
  },
  complex: {
    display: 'grid',
    gridTemplateColumns: ['1fr', null, '3fr 4fr'],
    gridGap: '2rem',

    overflowY: 'auto',
    overflowX: 'hidden',
    WebkitOverflowScrolling: 'touch',

    MsOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': { display: 'none!important' },

    minHeight: '60vh',
    maxHeight: '92vh',

    container: {
      justifyContent: 'space-between',
      gap: ['2rem', null, '6rem'],

      '>div': {
        alignItems: 'flex-start',
        gap: '2rem',
      },
    },

    body: {
      width: '100%',
      button: {
        width: '100%',
      },
    },

    '[aria-label="close"]': {
      alignSelf: 'flex-end',
    },

    aside: {
      height: ['20rem', null, '100%'],
      maxHeight: '90vh',
      '>*': {
        height: '100%',
      },
      img: {
        height: '100%',
        objectFit: 'contain',
      },
    },

    close: {
      display: [null, null, 'none'],
      justifySelf: 'flex-end',
    },
    hideMobile: {
      display: ['none', null, 'flex'],
    },
  },
  wrapper: {
    '&[data-layout=simple]': {
      width: ['calc(100vw - (var(--padding-x) * 2))', 'clamp(38rem, 38vw, 68rem)'],
    },
    '&[data-layout=complex]': {
      width: ['calc(100vw - (var(--padding-x) * 2))', 'clamp(38rem, 60vw, 80rem)'],
    },
    justifyContent: 'center',
  },
  container: {
    '--padding': '1.5em',

    flexGrow: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '3.5em',
    pt: 'var(--padding)',

    '&[data-with-refs=false]': { pb: 'var(--padding)' },
    '&[data-with-refs=true]': { pb: 0 },
  },
  body: {
    width: '100%',
    gap: '1em',
  },
  refs: {
    variant: 'modal.body',

    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: (t) => transparentize(0.85, t.rawColors.text),
    pt: 1,
  },
  ref: {
    display: 'flex',
    justifyContent: 'space-between',

    '>a,>span': {
      opacity: 0.3,

      span: {
        display: 'inline-block',
        size: '0.6em',
        ml: '0.4em',
      },
    },
  },
  text: {
    width: '100%',
    px: '3em',
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    px: '1em',
  },
  icon: {
    position: 'relative',
    svg: { width: 'unset', height: '8em' },
  },
}
