import { makeHighlight } from '@monobits/colors'
import { lighten, darken } from 'polished'

const opacity = 0.3

const colors = {
  light: '#F3F2EF',
  dark: '#000000',
  blue: '#389FFE',
  yellow: '#CEFF00',
  orange: '#FF6B00',
}

export default {
  text: colors.dark,
  background: colors.light,
  primary: colors.blue,
  secondary: colors.yellow,
  error: colors.orange,
  highlight: makeHighlight(colors.blue, opacity),
  overlay: makeHighlight(colors.dark, opacity),
  burger_bg: colors.blue,
  burger_color: colors.light,

  quarter: lighten(0.9, colors.dark),
  subdued: lighten(0.85, colors.dark),
  grey: makeHighlight(colors.dark, 0.5),
  ...colors,

  modes: {
    dark: {
      text: colors.light,
      background: colors.blue,
      primary: colors.light,
      secondary: colors.yellow,
      error: colors.orange,
      highlight: makeHighlight(colors.light, opacity),
      burger_bg: colors.light,
      burger_color: colors.blue,

      quarter: darken(0.1, colors.light),
      subdued: darken(0.15, colors.light),
      grey: makeHighlight(colors.light, 0.5),
    },
  },
}
