export default {
  __default: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1em',
    mb: 1,
  },
  button: {
    display: 'flex',
    flexGrow: 1,
    flexBasis: ['100%', '25%'],
    whiteSpace: 'nowrap',
  },
}
