/** @jsxImportSource theme-ui */
import { Heading, Text } from '@monobits/components'

import Box from './Box'

const { long, compact } = {
  long: new Intl.NumberFormat('en'),
  compact: new Intl.NumberFormat('en', { notation: 'compact' }),
}

const Meter = ({ text = '', values: [value, total] = [0, 0], infinite, sx, ...props }) => {
  const minimal = infinite || value > total

  return (
    <Box sx={{ variant: 'meter', ...sx }} {...props}>
      <Heading as="h4" variant="list" cropped={false}>
        {!minimal ? (
          <>
            {text}: {value ? long.format(value) : 0}&#8239;/&#8239;{total ? compact.format(total) : total}
          </>
        ) : (
          <>
            {text}: {value ? long.format(value) : 0}
          </>
        )}
      </Heading>

      {!minimal && (
        <>
          <div sx={{ variant: 'meter.head' }} aria-hidden={!!!total}>
            <Text rte={false} children={0} />
            <Text rte={false} children={long.format(total / 2)} />
            <Text rte={false} children={long.format(total)} />
          </div>

          <aside
            sx={{ variant: 'meter.indicator' }}
            style={{ '--progress': !!total ? (value / total) * 100 + '%' : '0%' }}
          >
            <div />
          </aside>
        </>
      )}
    </Box>
  )
}

export default Meter
