import { buildFonts, fontlist } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'size-cursor': ['18px', '21px', false],
    'size-percent': ['60px', '130px', false],
    'size-status': ['14px', '28px', false],
    'size-menu': ['24px', '46px', false],
    'size-text': ['11px', '16px'],
    'size-list': ['14px', '52px'],
    'size-item': ['30px', '62px'],
    'size-price': ['17px', '38px', false],
    'size-title': ['30px', '130px'],
  },
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 300,
    heading: 300,
  },
  lineHeights: {
    body: 1.26,
    heading: 1,
  },
}

export const rootFontStyles = {
  fontSize: 1,
  fontFamily: 'allianz',
  lineHeight: 'body',
  fontWeight: 'body',

  '--rte': '1.1em',
  '.-rte + .-rte': { mt: 'var(--rte)' },

  ...fluid.properties,
}

const common = (type = 'body', uppercase = false) => ({
  textTransform: uppercase ? 'uppercase' : null,
  fontFamily: type,
  fontWeight: type,
  lineHeight: type,
  crops: 'allianz',
})

const allianz = {
  family: 'ES Allianz',
  fallbacks: 'helvetica, arial, sans-serif',
  crop: [9, 8],
}

export default buildFonts([fontlist(['allianz'], { allianz }), scales], {
  fonts: {
    heading: 'inherit',
    body: 'inherit',
    monospace: 'Menlo, monospace',
  },
  variants: {
    key: 'text',
    heading: { variant: 'text.title' },
    __default: { variant: 'text.text' },
    __cropped: {
      title: { fontSize: 4, ...common('heading', false) },
      percent: { fontSize: 'var(--size-percent)', ...common('heading', false) },
      caption: { fontSize: 1, ...common('heading', true) },
      cursor: { fontSize: 'var(--size-cursor)', ...common('heading', true) },
      text: { fontSize: 1, ...common('body') },
      list: { fontSize: 2, ...common('body') },
      menu: { fontSize: 'var(--size-menu)', ...common('body') },
      item: { fontSize: 3, ...common('heading') },
      price: { fontSize: 'var(--size-price)', ...common('heading') },
    },
  },
})
