/** @jsxImportSource theme-ui */
import { Heading } from '@monobits/components'

const Field = ({ children, label, caption, info }) => (
  <div sx={{ variant: 'transaction.field' }}>
    <Heading as="label" variant="text">
      <span>{label}</span>
      {caption && <span>&nbsp;-&nbsp;{caption}</span>}
    </Heading>
    {children}
    {info && <small>{info}</small>}
  </div>
)

export default Field
