import { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useSafeState, useConditionalEffect, useUpdateEffect, useDebouncedState } from '@react-hookz/web'
import { useWallet, useConnectedWallet, WalletStatus } from '@terra-money/wallet-provider'
import { isEmpty, take } from 'lodash'
import axios from 'axios'

import { selectedListState, selectedListStatus, userDataRefreshCountState } from '../../atoms'
import { CONTRACT, DEFAULTS } from '../../__consts'

const useConnectionStatus = ({ stage_level = null, check_whitelist = true } = {}, [all, grouped]) => {
  const [data, setData] = useDebouncedState({}, 600, 1200)
  const [mintData, setMintData] = useSafeState({ total_supply: 10050, total_reservations: 0 })
  const [listed, setListed] = useSafeState(false)
  const { pathname } = useLocation()

  const route_enabled = true

  let rendered = useRef(false)

  const { status: walletStatus, connect, disconnect } = useWallet()
  const connectedWallet = useConnectedWallet()

  const ready = 'stages' in data
  const connected = !!(walletStatus === WalletStatus.WALLET_CONNECTED)
  const address = connected ? connectedWallet.walletAddress : null

  const status = useRecoilValue(selectedListStatus)
  const [userDataRefreshCount, setUserDataRefreshCount] = useRecoilState(userDataRefreshCountState)
  const [selected, setSelected] = useRecoilState(selectedListState)

  const soldOut = allEqual(Object.values(mintData))
  const ended = soldOut
    ? true
    : ready
    ? Object.values(data.stages).every((i) => Date.now() > i.stage.end_time * 1000)
    : false
  const started = ready ? Object.values(data.stages).some((i) => Date.now() > i.stage.start_time * 1000) : false

  const allowed = ready ? stage_level in data.stages || !!!check_whitelist || !!!data.user.stage : false

  const limits =
    ready &&
    Object.keys(all)?.map((key) => {
      return key in data?.stages ? data?.stages[key]?.stage?.stage_user_limit : 0
    })

  const acc_limit = take(limits, stage_level + 1).reduce((a, b) => a + b, 0)

  const max_mint = ready
    ? Object.values(data?.stages)
        ?.filter((i) => !!i.stage.check_whitelist)
        .map((i) => i.stage.stage_user_limit)
        .reduce((a, b) => a + b, 0)
    : 0
  const minted_by_user = ready ? data?.minted_by_user : 0
  const completed = ended ? true : started ? (status === 1 ? minted_by_user === acc_limit : false) : false

  let stageStatus = 'connect'
  if (connected && !ready) stageStatus = 'loading'
  if (connected && ready) {
    if (allowed) stageStatus = 'allowed'
    if (!allowed || !listed) stageStatus = 'prevented'
    if (completed) stageStatus = 'completed'
    if (ended) stageStatus = 'ended'
  }

  useConditionalEffect(
    () => {
      const keys = Object.keys(grouped)

      if (!!keys?.length && !!!rendered.current) {
        setSelected(getTimeStatus(grouped).toString())
        rendered.current = true
      }
    },
    [grouped, pathname],
    [route_enabled]
  )

  useConditionalEffect(
    () => {
      if (!!connected) {
        getUserDatas(address, setData, setListed)
      } else if (!isEmpty(data)) {
        setData({})
      }
    },
    [connected, pathname],
    [route_enabled]
  )

  useConditionalEffect(
    () => {
      setTimeout(() => getUserDatas(address, setData, setListed), 1000)
    },
    [userDataRefreshCount, pathname],
    [!!connected, route_enabled],
    undefined,
    useUpdateEffect
  )

  useConditionalEffect(
    () => {
      getMintDatas(setMintData)
      const interval = setInterval(() => getMintDatas(setMintData), DEFAULTS.RESERVATIONS_REFRESH_RATE)
      return () => clearInterval(interval)
    },
    [pathname],
    [route_enabled]
  )

  const checks = !!(connected && status === 1)

  return {
    ...mintData,
    event: { started, ended },
    user: {
      ready,
      connect,
      disconnect,
      connected,
      address,
      allowedActions: !!((checks && allowed && !completed) || (checks && !!!check_whitelist)),
      listed,

      allowed,
      completed,
      stageStatus,

      check_whitelist,
      max_acc_mint: acc_limit - minted_by_user,
      minted_by_user,
      max_mint,
    },
    list: {
      status,
      selected,
    },
    setUserDataRefreshCount,
    cropAddress,
  }
}

const source = `${CONTRACT.DOMAIN}/wasm/contracts/${CONTRACT.ADDRESS}`

async function getUserDatas(address, setter, setListed) {
  try {
    const res = await axios.get(
      `${source}/store?query_msg=%7B%22get_status%22:%20%7B%22address%22:%20%22${address}%22%7D%7D`
    )
    const obj = res.data.result
    setter({ ...obj, stages: reduceStages(obj.stages) })
    setListed(true)
  } catch (error) {
    setter({ user: {}, stages: [] })
    setListed(false)
    console.log(error)
  }
}

async function getMintDatas(setter) {
  try {
    const mint = await axios.get(source + CONTRACT.MINT_ROUTE)
    setter(mint.data.result)
  } catch (error) {
    console.log(error)
  }
}

function allEqual(arr) {
  return arr.every((val) => val === arr[0])
}

function reduceStages(array) {
  return array.reduce((acc, obj) => ({ ...acc, [obj.stage.stage_level]: obj }), {})
}

function cropAddress(string = '', range = 10) {
  const [start, end] = [
    string?.substr(0, range),
    string?.substr(string?.length - range, string?.length - 1),
    //
  ]

  return start + '...' + end
}

function getTimeStatus(stages) {
  const obj = stages
    ? Object.values(stages).reduce((acc, value) => {
        const now = new Date()
        const [start, end] = [value.start_time, value.end_time].map((i) => new Date(i * 1000))

        const before = start.getTime() - now.getTime()
        const until = end.getTime() - now.getTime()

        const type = before > 0 ? 0 : until > 0 ? 1 : 2

        return { ...acc, ...{ [value.stage_level]: type } }
      }, {})
    : {}

  const _array = Object.entries(obj)
  const _active = _array?.filter(([k, v]) => v === 1)?.[0]?.[0]
  const _upcoming = _array?.filter(([k, v]) => v === 0)?.[0]?.[0]

  return _active || _upcoming || Object.keys(stages)?.[0]
}

export default useConnectionStatus
