import { inset } from '../../utils'

export default {
  style: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text',

    '--border': 'var(--stroke-x) solid currentColor',

    borderRadius: (t) => t.space?.[1],
    overflow: 'hidden',
  },
  __default: {
    '--aspect-ratio': '2/3',
    position: 'relative',

    variant: 'card.style',

    '[data-socials]': {
      position: 'absolute',
      right: 1,
      bottom: 1,
      zIndex: '2',
      display: 'flex',
      gap: '0.5em',
      opacity: 0,
    },

    '@media (hover: hover)': {
      '&:hover [data-socials]': { opacity: 1 },
    },

    '[data-labels]': {
      position: 'absolute',
      right: '1em',
      top: '1em',
      zIndex: '2',
    },
    '[data-stock-label]': {
      position: 'absolute',
      left: '1em',
      top: [null, '1em'],
      bottom: ['1em', 'unset'],
      zIndex: '2',
    },
  },
  container: {
    minWidth: 0,
    '&[data-loaded=false]': {
      opacity: 0,
    },
    transition: 'opacity var(--ui-duration) var(--ui-ease)',

    '&[data-type="background"] h3': {
      color: 'white',
      mixBlendMode: 'difference',
    },
  },
  info: {
    '--p': ['1.25rem', null, 'var(--padding-x)'],
    '--ppy': ['1.5rem', null, 'var(--padding-x)'],

    py: 'var(--ppy)',
    px: 'var(--p)',
    mt: 'calc(var(--stroke-x) * -1.15)',
    variant: 'card.style',

    '&[data-bg=true]': {
      bg: 'background',
    },
    '&[data-bg=false]': {
      bg: 'white',
    },

    display: 'grid',
    gridColumnGap: '1em',
    gridRowGap: 'var(--p)',

    position: 'relative',
    zIndex: '2',

    '&[data-item=false]': {
      gridTemplateColumns: ['1fr', '1fr auto'],
    },
    '&[data-item=true]': {
      gridTemplateColumns: '1fr auto',
    },
    '[data-spaced=true]': {
      minHeight: ['2em', '3em', '3.5em'],
    },
    '[data-title]': {
      textTransform: 'capitalize',
      gap: '0.75em',
    },
    '[data-ranking]': {
      gridColumn: '1/-1',
      gridRow: '2/3',
    },
  },
  indicator: {
    whiteSpace: 'nowrap',
    mt: [null, '-0.25em'],
    mr: [null, '-0.25em'],
    p: '0.35em 0.85em',
    borderRadius: '100px',
    textTransform: 'capitalize',
    alignSelf: 'flex-start',
    textAlign: 'center',
    userSelect: 'none',
  },
  rank: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '0.6em',
    mt: '0.85em',
  },
  pricing: {
    borderTop: 'var(--border)',
    gridColumn: '1/-1',
    width: 'calc(100% + (var(--p) * 2))',
    ml: 'calc(var(--p) * -1)',
    pt: 'var(--p)',
    px: 'var(--p)',

    '>div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      mt: '2em',
    },

    svg: { size: '2em', mb: ['-0.45em', 0, '-0.15em'] },
  },
  marketplace: {
    variant: 'card.style',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    '--py': ['0.85em', '0.75em'],
    '--offset': 'calc(var(--py) * 2)',

    mt: 'calc(var(--offset) * -1)',
    pt: 'calc(var(--py) * 3)',
    pb: 'var(--py)',
    px: 'var(--p)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    userSelect: 'none',

    position: 'relative',
    zIndex: '1',

    fontSize: 'var(--size-status)',

    '&[data-disabled=false]': {
      bg: 'text',
      color: 'background',

      '@media (hover: hover)': {
        '&:hover': {
          bg: 'primary',
          borderColor: 'primary',
        },
      },
    },
    '&[data-disabled=true]': {
      bg: 'background',
    },
    '&[disabled]': {
      pointerEvents: 'none',
      opacity: 0.3,
    },

    '&[data-claimed=true]': {
      pointerEvents: 'none',
      opacity: 0.3,
    },
  },
  image: {
    borderRadius: (t) => t.space?.[1],
    position: 'absolute',
    ...inset(0),
    objectFit: 'cover',
    objectPosition: 'center center',
    userSelect: 'none',

    '&[data-loaded=false]': {
      opacity: 0,
    },
    transition: 'opacity var(--ui-duration) var(--ui-ease)',
  },
  video: {
    borderRadius: (t) => t.space?.[1],
    position: 'absolute',
    zIndex: 0,
    ...inset('-1px'),
    size: 'calc(100% + 2px)',
    objectFit: 'cover',
    objectPosition: 'center center',

    '&[data-loaded=false]': {
      opacity: 0,
    },
    transition: 'opacity var(--ui-duration) var(--ui-ease)',
  },
}
